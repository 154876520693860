import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import api from './api';

const storageKey = 'ff-local-overrides';

// All feature flags are kept in this enum here
// Please remove any flags that are no longer in use

export enum FeatureFlag {
  Communities = 'communities',
  CommunityActivityItems = 'communityActivityItems',
  CommunityAnonymisedMap = 'communityAnonymisedMap',
  CommunityConfig = 'communityConfig',
  CommunityMemberInvite = 'communityMemberInvite',
  CommunityMessageBoard = 'communityMessageBoard',
  CommunityNetwork = 'communityNetwork',
  CommunityPeers = 'communityPeers',
  CommunityProfile = 'communityProfile',
  ContinuousMonitoringInterest = 'continuousMonitoringInterest',
  DeclutterActivityItems = 'declutterActivityItems',
  DeclutterCommunityPosts = 'declutterCommunityPosts',
  DisableNetworkVis = 'disableNetworkVis',
  EmailNotifWithPermissions = 'emailNotifWithPermissions',
  ExternalMonitoringContextNotes = 'externalMonitoringContextNotes',
  ExternalMonitoringCoreWorkflow = 'externalMonitoringCoreWorkflow',
  ExternalMonitoringPaidClient = 'externalMonitoringPaidClient',
  ExternalMonitoringScanTypeDKIM = 'externalMonitoringScanTypeDKIM',
  ExternalMonitoringScanTypeDNS = 'externalMonitoringScanTypeDNS',
  ExternalMonitoringScanTypeTLS = 'externalMonitoringScanTypeTLS',
  ExternalMonitoringSupplier = 'externalMonitoringSupplier',
  FederatedCritical = 'federatedCritical',
  FederatedMutualConnections = 'federatedMutualConnections',
  FilterByConnectionStatus = 'filterByConnectionStatus',
  FrameworkAddOns = 'frameworkAddOns',
  FrameworkLevels = 'frameworkLevels',
  LogoUpload = 'logoUpload',
  NetworkVisExcludeSuppliers = 'networkVisExcludeSuppliers',
  PrivateDocuments = 'privateDocuments',
  PrivateSuppliers = 'privateSuppliers',
  Products = 'products',
  QuickAnswer = 'quickAnswer',
  RestrictSupplierAccess = 'restrictSupplierAccess',
  SuppliersCanAnswerMore = 'suppliersCanAnswerMore',
  SupplierWeeklyDigest = 'supplierWeeklyDigest',
  ThreatsNotifications = 'threats_notifications',
  ToggleSupplierPrivateOnProfile = 'toggleSupplierPrivateOnProfile',
  WhatsNextConnectionReqs = 'whatsNextConnectionReqs',
}

const useFeatureFlags = defineStore('featureFlags', () => {
  const flags = ref<Record<string, { enabled: boolean }>>({});
  const overrides = ref<Record<string, { enabled: boolean; local: boolean }>>({});
  const hasSetFlagsForContext = ref<'' | 'auth' | 'public'>('');

  // Will initialise this plugins default flags by calling the GET /flags API endpoint.
  // Any overrides persisted to localStorage will be set too.
  async function setFlags(isAuthenticated: boolean) {
    if (isAuthenticated && hasSetFlagsForContext.value === 'auth') return;
    if (!isAuthenticated && hasSetFlagsForContext.value === 'public') return;

    let res;
    if (isAuthenticated) {
      res = await api.fetchUserFlags();
      hasSetFlagsForContext.value = 'auth';
    } else {
      res = await api.fetchPublicFlags();
      hasSetFlagsForContext.value = 'public';
    }
    flags.value = res.data.flags;

    const storedString = window.localStorage.getItem(storageKey);
    const storedOverrides = storedString ? JSON.parse(storedString) : null;
    overrides.value = storedOverrides || {};
  }

  const flagsWithOverrides = computed<Record<string, { enabled: boolean; local: boolean }>>(() => {
    const flagsWithFalseLocal = Object.fromEntries(
      Object.entries(flags.value).map(([key, flag]) => [key, { ...flag, local: false }]),
    );
    return { ...flagsWithFalseLocal, ...overrides.value };
  });

  function getStatus(flag: FeatureFlag) {
    return flagsWithOverrides.value[flag]?.enabled || false;
  }

  function setOverride(flag: string, enabled: boolean) {
    if (flags.value[flag]?.enabled === enabled) {
      delete overrides.value[flag];
    } else {
      overrides.value[flag] = { enabled, local: true };
    }

    window.localStorage.setItem(storageKey, JSON.stringify(overrides.value));
  }

  const overridesForHeader = computed(() =>
    Object.entries(overrides.value).map(([key, { enabled }]) => `${key}=${enabled}`),
  );

  function resetAllOverrides() {
    overrides.value = {};

    window.localStorage.setItem(storageKey, JSON.stringify(overrides.value));
  }

  return {
    setFlags,
    flagsWithOverrides,
    ff: getStatus,
    setOverride,
    overridesForHeader,
    resetAllOverrides,
  };
});

export default useFeatureFlags;
