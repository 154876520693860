import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { useStore } from 'vuex';
import type { MutationObserverOptions } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import { isAuthenticatedByRLRef } from '@/modules/auth/auth';
import cognitoAuth from '@/legacy/auth/cognito';
import type { User } from '@/types/models/user';
import useFeatureFlags, { FeatureFlag } from '@/composables/feature-flags';
import { COMMUNITY_POSTS_QUERY } from '@/modules/communitiesMessageBoard/composables';
import type {
  GetCommunityPostResponse,
  GetCommunityPostsResponse,
} from '@/modules/communitiesMessageBoard/api';

import api, { type UpdateUserVariables, type UserPermissionsResponse } from './api';

export const CURRENT_USER_QUERY = '/user';
export const CURRENT_USER_PERMISSIONS_QUERY = '/user/permissions';

export function useCurrentUser(options?: QueryOptions<User>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [CURRENT_USER_QUERY],
      async queryFn() {
        const res = await api.fetchCurrentUser();
        return res.data;
      },
      staleTime: Infinity,
      enabled: isAuthenticatedByRLRef,
    }),
  );

  const user = computed(() => query.data.value);

  const store = useStore();
  watch(user, async (newUser, oldUser) => {
    // If phone changed, send validation code
    if (oldUser && oldUser.phone !== '' && newUser && oldUser.phone !== newUser.phone) {
      await cognitoAuth.resendValidationCode('phone_number');
    }

    // Update cognito user
    if (store) store.dispatch('legacy/getCognitoUser');
  });

  const fullName = computed(() => user.value && `${user.value.firstName} ${user.value.lastName}`);
  const currentUserID = computed(() => user.value?.userID);

  return { ...query, user, fullName, currentUserID };
}

export function useUpdateCurrentUser(
  options?: MutationObserverOptions<User, AxiosError, UpdateUserVariables, never>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(user) {
        const res = await api.updateUser(user);
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData([CURRENT_USER_QUERY], data);

        queryClient.setQueriesData(
          { queryKey: [COMMUNITY_POSTS_QUERY] },
          (oldData: GetCommunityPostsResponse | GetCommunityPostResponse | undefined) => {
            if (!oldData) return;
            // This can either be post or posts response
            return {
              ...oldData,
              users:
                data.userID in oldData.users
                  ? {
                      ...oldData.users,
                      [data.userID]: {
                        ...oldData.users[data.userID],
                        ...data,
                      },
                    }
                  : oldData.users,
            } satisfies typeof oldData;
          },
        );
      },
    }),
  );
}

export function useCurrentUserPermissions(options?: QueryOptions<UserPermissionsResponse>) {
  const queryClient = useQueryClient();
  const { ff } = useFeatureFlags();

  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [CURRENT_USER_PERMISSIONS_QUERY],
      async queryFn() {
        const res = await api.fetchCurrentUserPermissions();
        return res.data;
      },
      staleTime: Infinity,
      enabled: isAuthenticatedByRLRef,
    }),
  );

  const permissions = computed<string[]>(() => {
    if (!query.data.value) return [];
    if (Array.isArray(query.data.value)) return query.data.value;
    return query.data.value.permissions;
  });

  const accessibleSuppliers = computed(() => {
    if (ff(FeatureFlag.RestrictSupplierAccess)) {
      if (!query.data.value || Array.isArray(query.data.value)) return [];
      return query.data.value.accessibleSuppliers;
    }
    return [];
  });

  function override(permissions: string[]) {
    queryClient.setQueryData([CURRENT_USER_PERMISSIONS_QUERY], permissions);
  }

  return { ...query, permissions, override, accessibleSuppliers };
}
